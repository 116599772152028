@import "../../Global.scss";

.menu {
  &Container {
    @include breakpoint(mobile-down) {
      @include position-fixed;
      @include border-bottom-primary;
      @include secondary-background;
      @include padding-large;
      width: 100%;
      top: 40px; 
    }

    @include breakpoint(tablet-up) {
      align-items: flex-start;
      background: transparent;
      @include padding-left-large;
      @include margin-left-large;
    }

    @include position-absolute;
    @include flex-column-align-center;
    z-index: 1;
    width: 120px;
    top: 20px;

    &Closed {
      @include breakpoint(mobile-down) {
        @include display-none;
      }
    }

    &Fixed {
      @include breakpoint(tablet-up) {
        @include position-fixed;
      }
    }
  }

  &Item {
    @include primary-color;
    @include padding-large;
    text-decoration: none;
    font-weight: bold;
    @include cursor-pointer;

    @include breakpoint(tablet-up) {
      @include cursor-pointer;
      @include body-text;
      color: $rotn-black;
      @include padding-left-small;
    }
  }
}