// Margin

@mixin margin-zero {
  @include margin-top-zero();
  @include margin-bottom-zero();
  @include margin-left-zero();
  @include margin-right-zero();
}

@mixin margin-top-zero {
  margin-top: 0;
}

@mixin margin-bottom-zero {
  margin-bottom: 0;
}

@mixin margin-left-zero {
  margin-left: 0;
}

@mixin margin-right-zero {
  margin-right: 0;
}


@mixin margin-small {
  @include margin-top-small();
  @include margin-bottom-small();
  @include margin-left-small();
  @include margin-right-small();
}

@mixin margin-top-small {
  margin-top: $margin-small;
}

@mixin margin-bottom-small {
  margin-bottom: $margin-small;
}

@mixin margin-left-small {
  margin-left: $margin-small;
}

@mixin margin-right-small {
  margin-right: $margin-small;
}

@mixin margin-large {
  @include margin-top-large();
  @include margin-bottom-large();
  @include margin-left-large();
  @include margin-right-large();
}

@mixin margin-top-large {
  margin-top: $margin-large;
}

@mixin margin-bottom-large {
  margin-bottom: $margin-large;
}

@mixin margin-left-large {
  margin-left: $margin-small;
}

@mixin margin-right-large {
  margin-right: $margin-small;
}

// Padding

@mixin padding-zero {
  @include padding-top-zero();
  @include padding-bottom-zero();
  @include padding-left-zero();
  @include padding-right-zero();
}

@mixin padding-top-zero {
  padding-top: 0;
}

@mixin padding-bottom-zero {
  padding-bottom: 0;
}

@mixin padding-left-zero {
  padding-left: 0;
}

@mixin padding-right-zero {
  padding-right: 0;
}

@mixin padding-small {
  @include padding-top-small();
  @include padding-bottom-small();
  @include padding-left-small();
  @include padding-right-small();
}

@mixin padding-top-small {
  padding-top: $padding-small;
}

@mixin padding-bottom-small {
  padding-bottom: $padding-small;
}

@mixin padding-left-small {
  padding-left: $padding-small;
}

@mixin padding-right-small {
  padding-right: $padding-small;
}

@mixin padding-large {
  @include padding-top-large();
  @include padding-bottom-large();
  @include padding-left-large();
  @include padding-right-large();
}

@mixin padding-top-large {
  padding-top: $padding-large;
}

@mixin padding-bottom-large {
  padding-bottom: $padding-large;
}

@mixin padding-left-large {
  padding-left: $padding-large;
}

@mixin padding-right-large {
  padding-right: $padding-large;
}