// Font Mixins 

@mixin font-family-default {
  font-family: $rotn-font-family;
}

@mixin large-text {
  font-size: $font-size-large;
}

@mixin medium-text {
  font-size: $font-size-medium;
}

@mixin body-text {
  font-size: $font-size-body;
}

@mixin text-centered {
  text-align: center;
}

@mixin text-left {
  text-align: left;
}

@mixin text-right {
  text-align: right;
}

@mixin text-justified {
  text-align: justify;
}

@mixin header-letter-spacing {
  letter-spacing: $header-letter-spacing;
}