// Display

@mixin display-none {
  display: none;
}

// Position

@mixin position-absolute {
  position: absolute;
}

@mixin position-fixed {
  position: fixed;
}

@mixin position-relative {
  position: relative;
}

// Overlays 

@mixin overlay {
  @include position-fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}