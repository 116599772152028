@import "../Global.scss";

.social {

  &Container {
    @include flex-row-align-center-justify-between;
    width: 130px;
    padding-right: $border-width;
  }

  &Link {
    @include icon-width-default;
  }

  &Icon {
    @include icon-style-default;
  }
}