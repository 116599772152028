@import "./styles/normalize.scss";
@import "./styles/resets.scss";
@import "./Global.scss";

html, body {
  overscroll-behavior-y: none;
  margin: 0;
}

.App {
  @include primary-background;
  @include font-family-default;
  overscroll-behavior-y: none;
  margin: 0;
}

.mainSiteWrapper {
  @include margin-top-zero;
  @include position-relative;
  overscroll-behavior-y: none;
  margin: 0;
}