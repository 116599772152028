@import "../Global.scss";

.testimonialsContainer {
  @include flex-column-align-center;
  @include margin-large;
  @include global-section-width;
  margin-left: auto;
  margin-right: auto;

  &Heading {
    @include header-styling;
    @include margin-top-large;
  }
}