@import "../../Global.scss";

.repertoireItem {
  @include flex-row-align-start-justify-between;
  @include body-text;
  @include padding-top-large;
  @include padding-bottom-large;
  @include global-section-width;

  @include breakpoint(tablet-up) {
    width: $tablet-section-width;
  }

  @include breakpoint(mobile-small-down) {
    @include padding-top-small;
    @include padding-bottom-small;
  }

  &:first-child {
    @include padding-top-zero;
  }

  &:last-child {
    @include padding-bottom-zero;
  }

  &Title, &Artist {
    max-width: 50%;
  }

  &Title {  
    @include text-left;
    font-style: italic
  }

  &Artist {
    @include text-right;
    font-weight: bold;
  }
}