@import "../Global.scss";

.gallery {

  &Wrapper {
    @include flex-column-align-center-justify-between;
    @include margin-large;
  }

  &Header {
    @include header-styling-primary;
    @include margin-large;
  }

  &Container {
    @include flex-row-align-center-justify-evenly-wrap;
    @include margin-large;
    @include global-section-width;
  }

  &Image, &ImageSolo {
    @include border-primary;
    @include margin-large;
    @include cursor-pointer;
    width: 150px;

    @include breakpoint(mobile-down) {
      width: 115px;
    }

    @include breakpoint(tablet-up) {
      width: 210px;
    }

    &Solo {
      @include global-section-width;

      @include breakpoint(tablet-up) {
        width: 600px;
      }

      @include breakpoint(desktop-up) {
        width: 750px;
      }

      &Tall {
        height: 250px;
        width: auto;

        @include breakpoint(tablet-up) {
          height: 500px;
        }
      }

      &Wrapper {
        @include flex-column-align-center-justify-center;
        @include overlay;
        z-index: 3;
        background-color: rgba(0,0,0,0.85);
      }

      &Button {
        @include icon-width-default;
        @include cursor-pointer;
        @include margin-top-large;

        &Wrapper {
          @include margin-top-large;
          @include flex-row-align-center-justify-evenly;
          @include global-section-width;
          @include breakpoint(tablet-up) {
            width: $tablet-section-width;
          }
        }
      }
    }
  }
}