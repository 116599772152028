//  Flex Mixins
@mixin flex {
  display: flex;
}

@mixin inline-flex {
  display: inline-flex;
}

@mixin direction-column {
  flex-direction: column;
}

@mixin direction-row {
  flex-direction: row;
}

@mixin align-center {
  align-items: center;
}

@mixin align-start {
  align-items: flex-start;
}

@mixin justify-evenly {
  justify-content: space-evenly;
}

@mixin justify-between {
  justify-content: space-between;
}

@mixin justify-center {
  justify-content: center;
}

@mixin flex-wrap {
  flex-wrap: wrap
}

@mixin flex-column {
  @include flex();
  @include direction-column();
}

@mixin flex-row {
  @include flex();
  @include direction-row();
}

@mixin flex-column-align-center {
  @include flex-column();
  @include align-center();
}

@mixin flex-row-align-center {
  @include flex-row();
  @include align-center();
}

@mixin flex-row-align-start {
  @include flex-row();
  @include align-start();
}

@mixin flex-row-align-center-justify-evenly {
  @include flex-row-align-center();
  @include justify-evenly();
}

@mixin flex-column-align-center-justify-evenly {
  @include flex-column-align-center();
  @include justify-evenly();
}

@mixin flex-row-align-center-justify-between {
  @include flex-row-align-center();
  @include justify-between();
}

@mixin flex-row-align-start-justify-between {
  @include flex-row-align-start;
  @include justify-between;
}

@mixin flex-column-align-center-justify-between {
  @include flex-column-align-center();
  @include justify-between();
}

@mixin flex-row-align-center-justify-center {
  @include flex-row-align-center();
  @include justify-center();
}

@mixin flex-column-align-center-justify-center {
  @include flex-column-align-center();
  @include justify-center();
}

@mixin flex-row-align-center-justify-evenly-wrap {
  @include flex-row-align-center-justify-evenly;
  @include flex-wrap;
}

@mixin flex-column-align-center-justify-evenly-wrap {
  @include flex-column-align-center-justify-evenly;
  @include flex-wrap;
}

@mixin flex-row-align-center-justify-between-wrap {
  @include flex-row-align-center-justify-between;
  @include flex-wrap;
}

@mixin flex-column-align-center-justify-between-wrap {
  @include flex-column-align-center-justify-between;
  @include flex-wrap;
}

@mixin flex-row-align-center-justify-center-wrap {
  @include flex-row-align-center-justify-center;
  @include flex-wrap;
}

@mixin flex-column-align-center-justify-center-wrap {
  @include flex-column-align-center-justify-center;
  @include flex-wrap;
}