@import "../Global.scss";

.clientsContainer {
  @include flex-row-align-center-justify-evenly-wrap;
  @include margin-large;
  @include global-section-width;

  &Wrapper {
    @include flex-column-align-center-justify-center;
    @include margin-large;
  }

  &Heading {
    @include header-styling-primary;
    @include margin-large;
  }

  &Image {
   @include padding-large;
   width: 66px;

   @include breakpoint(tablet-up) {
     width: 90px;
   }

   @include breakpoint(desktop-up) {
    width: 120px;
  }
  }
}