@import "../../Global.scss";

.instagramProfile {
  @include flex-column-align-center-justify-center;
  @include margin-top-large;
  @include margin-bottom-large;

  &Header {
    @include header-styling-secondary;
    @include margin-top-large;

    &Wrapper {
      @include position-relative;
    }
  }

  &Icon {
      @include position-absolute;
      right: 0;
      bottom: $icon-width-default / 3;
  }

  &Feed {
    @include margin-top-large;
    @include primary-background;
    @include border-none;
    width: $mobile-section-width;
    height: $mobile-section-width;

    @include breakpoint(tablet-up) {
      width: $tablet-section-width;
      height: $tablet-section-width;
    }

    @include breakpoint(desktop-up) {
      width: $desktop-section-width;
      height: $desktop-section-width;
    }

    @include breakpoint(mobile-small-down) {
      width: $mobile-small-section-width;
      height: $mobile-small-section-width;
    }
    
  }
}