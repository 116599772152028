@import '../Global.scss';

.carousel {
  &Container {
    @include flex-column-align-center;
    @include margin-small;
    @include global-section-width;
  }

  &ButtonContainer {
    @include flex-row-align-center-justify-between;
    @include margin-small;
    width: 60%;
  }

  &Count {
    @include header-letter-spacing;
    @include body-text;
    @include margin-top-small;
  }

  &Button {
    @include margin-small;
    @include cursor-pointer;
    width: 20px;
    height: auto;

    &Disabled {
      @include cursor-default;
      opacity: 0.5;
    }
  }
}
