@import "../Global.scss";

.repertoire{
  &Container {
    @include margin-top-large;
    @include margin-bottom-large;
    @include flex-column-align-center;
    @include global-section-width;
    margin-left: auto;
    margin-right: auto;
  }

  &Heading {
    @include header-styling;
    @include margin-top-large;
  }
}