@import "../Global.scss";

.form {
  
  &Container, &Wrapper {
    @include flex-column-align-center-justify-center;
  }

  &Container {
    @include medium-text;
    @include text-centered;
    @include secondary-color;
    z-index: 3;
    background-color: transparent;

    &, &Scrim {
      @include overlay;
    }

    &Scrim {
      z-index: 3;
      background-color: rgba(0,0,0,0.85);
    }
  }

  &PageWrapper {
    @include breakpoint(mobile-down) {
      overflow-y: auto;
    }
    @include breakpoint(mobile-small-down) {
      margin-top: 50px;
    }
    @include breakpoint(desktop-up) {
      @include margin-top-large;
    }
  }

  &Wrapper {
    @include breakpoint(mobile-down) {
      margin-left: auto;
      margin-right: auto;
    }
    @include global-section-width;
    @include padding-top-large;
    @include padding-bottom-large;
  }

  &Page {
    &Heading {
      @include padding-bottom-large;
      @include breakpoint(mobile-down) {
        @include padding-right-small;
        @include padding-left-small;
      }
    }
  }

  &InputsWrapper {
    @include breakpoint(desktop-up) {
      margin-right: 150px;
    }
  }

  &Input {
    &Textarea, &Input {
      @include global-section-width;

      @include breakpoint(desktop-up) {
        @include tablet-section-width;

      }
    }

    &Wrapper {
      @include flex-column-align-center-justify-center;
      @include breakpoint(desktop-up) {
        @include flex-row-align-center;
        @include padding-right-large;
        @include desktop-section-width;
        justify-content: flex-end;
      } 

      
    }

    &Label {
      @include breakpoint(desktop-up) {
        @include margin-right-large;
        @include padding-right-large;
      }
      &Incomplete {
        @include primary-color;
      }
    }

    &Textarea, &Input {
      @include margin-large;
      @include padding-small;
      @include body-text;
      @include border-primary;
      border-color: transparent;

      &Incomplete {
        border-color: $rotn-primary-color;
      }
    }

    &Textarea {
      height: 100px;
      @include breakpoint(desktop-up) {
        height: 70px;
      }
    }

    &Warning {
      @include body-text;
      @include margin-large;
      min-height: 20px;

      @include breakpoint(mobile-down) {
        @include medium-text;
        min-height: 50px;
      }

      &Incomplete {
        @include primary-color;
      }
    }
  }

  &Button {

    &Primary, &Secondary {
      @include margin-large;
      @include padding-large;
      @include medium-text;
      @include cursor-pointer;
      @include border-none;

      outline-width: $border-width;
      outline-style: solid;
    }

    &Primary {
      @include secondary-color;
      @include primary-background;
    }

    &Secondary {
      @include primary-color;
      @include secondary-background;
    }

    &Wrapper {
      @include flex-row-align-center-justify-evenly;
      @include global-section-width;
    }
  }

  &Message{
    
    &Sending, &Failed {
      @include large-text;
      @include text-centered;
      @include margin-bottom-large;
      @include padding-bottom-large;
    }

    &Sending {
      @include secondary-color;
    }

    &Failed {
      @include primary-color;
    }
  }

  &LoadingSpinner {
    @include loading-spinner;
    @include flex-column-align-center-justify-center;
    @include margin-large;
    @include padding-large;
    @include position-relative;
    width: $spinner-width;
    height: $spinner-width;
    top: $spinner-width - $icon-width-default;
    left: $spinner-width - ($icon-width-default / 2);
  }

}