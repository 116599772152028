@import "../../Global.scss";

.header {
  @include position-relative;
  @include secondary-color;
  @include breakpoint(tablet-up) {
    @include flex-row-align-center-justify-between;
  }

  &Anchor {
    @include position-absolute;
    top: -40px;

    @include breakpoint(tablet-up) {
      top: 1px;
    }
  }
    
  &Main {
    @include large-text;
    @include header-letter-spacing;
    @include breakpoint(mobile-small-down) {
      @include medium-text;
    }
  }

  &Sub {
    @include body-text;
  }

  &WrapperTopline {
    @include breakpoint(mobile-down) {
      @include fixed-header;
    }   
    @include primary-background;
    z-index: 3;
  }

  &Wrapper, &WrapperTopline {
    @include flex-row-align-center-justify-between;
    @include padding-small;
    height: 30px;
    @include breakpoint(tablet-up) {
      width: 50%;
    }
  }

  &Wrapper {
    margin-top: 40px;
    @include breakpoint(tablet-up) {
      margin-top: 0;
      z-index: 3;
      @include primary-background;
    }
  }

  &MenuButton {
    @include icon-width-default;
    @include cursor-pointer;
    @include breakpoint(tablet-up) {
      display: none;
    }
  }
}