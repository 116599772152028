@import '../../Global.scss';

.repertoire {
  &Page {
    @include flex-column-align-center-justify-center;
    @include border-top-secondary;
    @include border-bottom-secondary;
    @include margin-bottom-large;
    @include global-section-width;
    height: 450px;
    overflow: auto;

    @include breakpoint(tablet-up) {
      height: 360px;
    }
    @include breakpoint(mobile-small-down) {
      height: 360px
    }
  }

  &Loading {
    @include body-text;
    @include margin-top-large;
  }
}