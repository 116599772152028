@import "../Global.scss";

.contact{
  
  &Container {
    @include flex-column-align-center;
    @include margin-large;
    @include margin-bottom-zero;
    @include padding-bottom-large;

    @include breakpoint(tablet-up) {
      @include flex-row-align-center-justify-evenly;
    }
  }

  &Link {

    &Wrapper {
      @include margin-small;
    } 

    &, &Wrapper {
      @include body-text;
      @include secondary-color;
      text-decoration: none;
    }
  }

  &SocialContainer {
    @include margin-top-large;

    @include breakpoint(tablet-up) {
      @include margin-top-zero;
    }
  }
}