// Colors
@mixin primary-color {
  color: $rotn-primary-color;
}

@mixin secondary-color {
  color: $rotn-secondary-color;
}

@mixin primary-background {
  background: $rotn-primary-color;
}

@mixin secondary-background {
  background: $rotn-secondary-color;
}