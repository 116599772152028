@import "../../Global.scss";

.soundcloudPlayer {
  @include flex-column-align-center-justify-evenly;
  @include margin-top-large;
  @include margin-bottom-large;

  &Header {
    @include header-styling-primary;
    @include margin-large;
  }

  &Frame {
    @include margin-top-large;
    @include margin-bottom-large;
    @include global-section-width;
    height: 450px;
  }
}