@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);
  
  @if $type == string {
    @if $min == mobile-small-down { 
      @media (max-width: $phone-min-width - 1) { @content; }       // Small Mobile Devices
    }
    @else if $min == mobile-small-up { 
      @media (min-width: $phone-min-width) { @content; }       // Small Mobile Devices
    }
    @else if $min == mobile-down { 
      @media (max-width: $tablet-min-width - 1) { @content; }       // Mobile Devices
    }
    @else if $min == mobile-up { 
      @media (min-width: $tablet-min-width) { @content; }       // Mobile Devices
    }
    @else if $min == tablet-down {
      @media (max-width: $tablet-min-width -1) { @content; }       // Tablet Devices
    }
    @else if $min == tablet-up {
      @media (min-width: $tablet-min-width) { @content; }       // Tablet Devices
    }
    @else if $min == desktop-down {
      @media (max-width: $desktop-min-width -1) { @content; }       // Desktops
    } 
    @else if $min == desktop-up {
      @media (min-width: $desktop-min-width) { @content; }       // Desktops
    } 
    @else if $min == desktop-wide-down {
      @media (max-width: $desktop-wide-min-width -1) { @content; }      // Widescreen Desktops
    }
    @else if $min == desktop-wide-up {
      @media (min-width: $desktop-wide-min-width) { @content; }      // Widescreen Desktops
    }
  }
  @else if $type == number {
    $query: "all" !default;
    @if      $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; }
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; }
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; }
    @media #{$query} { @content; }
  }
}