@import "../Global.scss";

.quoteButton {
  @include body-text;
  @include border-none;
  @include cursor-pointer;
  letter-spacing: 0.2px;
  outline-style: none;

  &Red, &White, &Menu {
    @include padding-small;
    @include margin-top-large;
    @include margin-bottom-large;
    outline-width: $border-width;
    outline-style: solid;
  }

  &Red {
    @include secondary-color;
    @include primary-background();  
  }

  &White {
    @include primary-color;
    @include secondary-background;
  }

  &Menu {
    @include secondary-color;
    @include primary-background;
    font-weight: bold;

    @include breakpoint(tablet-up) {
      color: $rotn-black;
      background: transparent;
    }
  }

}