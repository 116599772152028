@import "../Global.scss";

.sectionContainer {
  @include position-relative;
  @include secondary-color;
  @include primary-background;

  &:nth-of-type(2n) {
    @include primary-color;
    @include secondary-background;
  }

  &Anchor {
    @include position-absolute;
    top: -40px;

    @include breakpoint(tablet-up) {
      top: 1px;
    }
  }
}