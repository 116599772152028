@mixin mobile-section-width {
  width: $mobile-section-width
}

@mixin mobile-small-section-width {
  width: $mobile-small-section-width
}

@mixin tablet-section-width {
  width: $tablet-section-width
}

@mixin desktop-section-width {
  width: $desktop-section-width
}

@mixin global-section-width {
  @include mobile-small-section-width;
  @include breakpoint(mobile-up) {
    @include mobile-section-width;
  };
  @include breakpoint(tablet-up) {
    @include tablet-section-width;
  };
  @include breakpoint(desktop-up) {
    @include desktop-section-width;
  };
}

