@import "../../Global.scss";

.testimonialCard {
    height: 300px;
    overflow: auto;
    @include flex-column-align-center;
    @include border-top-secondary;
    @include border-bottom-secondary;
    @include padding-bottom-large;
    @include global-section-width;

    @include breakpoint(tablet-up) {
      height: 250px;
    }

    &Wrapper {
      @include flex-column-align-center-justify-center;
      @include cursor-pointer;
      @include position-relative;
    }

    &Testimonial {
      margin-top: auto;
      margin-bottom: auto;
      @include margin-left-large;
      @include margin-right-large;
      @include padding-large;
      @include body-text;
      @include text-centered;

      @include breakpoint(tablet-up) {
        width: $tablet-section-width;
      }
    }

    &Client {
      @include padding-large;
      @include margin-top-large;
    }
  }