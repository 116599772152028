@import '../../Global.scss';

.bio {
  &Content {
    @include flex-column-align-center-justify-evenly;
    @include primary-color;
    @include padding-small;
    @include padding-bottom-large;
    margin-left: auto;
    margin-right: auto;

    &,
    &Higher,
    &Lower {
      @include global-section-width;
    }

    &Higher {
      @include breakpoint(tablet-up) {
        @include flex-row-align-center-justify-between;
        flex-direction: row-reverse;
      }
    }

    &Lower {
      @include flex-row-align-center-justify-between;
    }
  }

  &Header {
    @include header-styling-primary;
    @include margin-small;
  }

  &Text {
    @include padding-top-large;
    @include padding-bottom-large;
    @include body-text;
    @include text-centered;
    color: $rotn-black;

    @include breakpoint(tablet-up) {
      @include padding-left-large;
      @include padding-right-large;
      @include text-justified;
    }
  }

  &Image,
  &ImageLower {
    @include margin-top-large;
    @include margin-bottom-large;
    @include border-primary;

    @include breakpoint(tablet-up) {
      @include margin-left-large;
      @include margin-right-large;
    }
  }

  &Image {
    @include global-section-width;
    @include breakpoint(tablet-up) {
      width: 190px;
    }

    @include breakpoint(desktop-up) {
      width: 160px;
    }
  }

  &ImageLower {
    width: 210px;
    @include breakpoint(mobile-down) {
      @include display-none;
    }

    @include breakpoint(desktop-up) {
      width: 190px;
    }
  }
}
