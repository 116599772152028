@import "../../Global.scss";

.youtubeWrapper {
  @include margin-bottom-zero;
  height: 56.25vw;
  width: 100vw;

  &Frame {
    height: inherit;
    width: inherit;
  }
}