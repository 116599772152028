// Fonts
$rotn-font-family: 'Montserrat', sans-serif;

//  ROTN Colours
$rotn-red: #ef3a3a;
$rotn-orange: #ffcf38;
$rotn-yellow: #ffdd7d;
$rotn-white: white;
$rotn-black: black;

$rotn-primary-color: $rotn-red;
$rotn-secondary-color: $rotn-white;

// Border Styling
$border-width: 2px;
$border-style: solid;

// Font Sizes 
$font-size-large: 23px;
$font-size-medium: 18px;
$font-size-body: 14px;

// Social Icon Variables
$icon-width-default: 25px;
$icon-border-radius: 50%;

// Padding
$padding-small: 5px;
$padding-large: 10px;

// Margin
$margin-small: 5px;
$margin-large: 10px;

//  Header Indents
$header-indent-small: 20px;
$header-indent-large: 30px;

// Letter Spacing

$header-letter-spacing: 2px; 

// Spinner Animation

$spinner-width: 50px;

@keyframes loadingSpinner {
  0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }
  100% {
       transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
}

//  Default Media Sizes

$phone-min-width: 350px;

$tablet-min-width: 768px;

$desktop-min-width: 950px;

$desktop-wide-min-width: 1200px;

// Section Widths

$mobile-section-width: 365px;

$mobile-small-section-width: 260px;

$tablet-section-width: 470px;

$desktop-section-width: 650px;

$desktop-wide-section-width: 950px;