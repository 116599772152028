// Social Icon Mixins

@mixin icon-width-default {
  width: $icon-width-default;
}

@mixin icon-border-radius {
  border-radius: $icon-border-radius;
}

@mixin icon-style-default {
  @include border-secondary();
  @include icon-border-radius();
  @include icon-width-default();
}

@mixin cursor-pointer {
  cursor: pointer;
}

@mixin cursor-default {
  cursor: default;
}

@mixin loading-spinner {
  animation: 1.5s linear infinite loadingSpinner;
}