// Header Styling 
@mixin header-styling {
  @include medium-text;
  @include text-centered;
  @include padding-bottom-large;
  @include header-letter-spacing;
  @include global-section-width;
}

@mixin header-styling-primary {
  @include header-styling;
  @include border-bottom-primary;
}

@mixin header-styling-secondary {
  @include header-styling;
  @include border-bottom-secondary;
}

@mixin header-indent-small {
  margin-left: $header-indent-small;
}

@mixin header-indent-large {
  margin-left: $header-indent-large;
}

// Fixed Header 

@mixin fixed-header {
  @include position-fixed;
  top: 0;
  left: 0;
  right: 0;
}

// Border Styling

@mixin border-none {
  border: none;
}

@mixin border-top-none {
  border-top: none;
}

@mixin border-bottom-none {
  border-bottom: none;
}

@mixin border-primary {
  border: $border-width $border-style $rotn-primary-color;
}

@mixin border-secondary {
  border: $border-width $border-style $rotn-secondary-color;
}

@mixin border-top {
  border-top-width: $border-width;
  border-top-style: $border-style;
}

@mixin border-top-primary {
  @include border-top;
  border-top-color: $rotn-primary-color;
}

@mixin border-top-secondary {
  @include border-top;
  border-top-color: $rotn-secondary-color;
}

@mixin border-bottom {
  border-bottom-width: $border-width;
  border-bottom-style: $border-style;
}

@mixin border-bottom-primary {
  @include border-bottom;
  border-bottom-color: $rotn-primary-color;
}

@mixin border-bottom-secondary {
  @include border-bottom;
  border-bottom-color: $rotn-secondary-color;
}